import {
  CLEAR_REQUEST,
  SET_CASE_NUMBER,
  SET_COST_CONCEPTS,
  SET_CURRENT_CARD,
  SET_CURRENT_MATERIAL,
  SET_CURRENT_PLAN,
  SET_CURRENT_SERVICE,
  SET_CURRENT_SERVICE_NAME,
  SET_CURRENT_STEP,
  SET_DESCRIPTION_PAYLOAD,
  SET_DYNAMIC_FORM,
  SET_HAVE_SERVICE_DESTINATION,
} from './../constants/requestActionsTypes';
import { RequestActions, RequestState } from './../constants/requestTypes';

const initialState: RequestState = {
  step: 0,
  serviceId: 0,
  serviceName: '',
  selectedCard: '',
  materialSelected: {},
  planSelected: {},
  descriptionData: {},
  haveServiceDestination: false,
  caseNumber: '',
  serviceCategory: '',
  costConcepts: [],
  dynamicForm: {},
};

export default (state = initialState, action: RequestActions) => {
  switch (action.type) {
    case SET_CURRENT_STEP:
      return {
        ...state,
        step: action.payload.step,
      };
    case SET_CASE_NUMBER:
      return {
        ...state,
        caseNumber: action.caseNumber,
      };
    case SET_CURRENT_SERVICE:
      return {
        ...state,
        serviceId: action.payload.serviceId,
        serviceCategory: action.payload.serviceCategory,
      };
    case SET_CURRENT_SERVICE_NAME:
      return {
        ...state,
        serviceName: action.serviceName,
      };
    case SET_CURRENT_CARD:
      return {
        ...state,
        selectedCard: action.payload.selectedCard,
      };
    case SET_CURRENT_MATERIAL:
      return {
        ...state,
        materialSelected: action.payload.materialSelected,
      };
    case SET_CURRENT_PLAN:
      return {
        ...state,
        planSelected: action.payload.planSelected,
      };
    case SET_DESCRIPTION_PAYLOAD:
      return {
        ...state,
        descriptionData: action.descriptionData,
      };
    case SET_COST_CONCEPTS:
      return {
        ...state,
        costConcepts: action.costConcepts,
      };
    case SET_DYNAMIC_FORM:
      return {
        ...state,
        dynamicForm: action.dynamicForm,
      };
    case CLEAR_REQUEST:
      return {
        ...state,
        step: 0,
        serviceId: 0,
        selectedCard: '',
        materialSelected: {},
        planSelected: {},
        caseNumber: '',
        descriptionData: '',
      };
    case SET_HAVE_SERVICE_DESTINATION:
      return {
        ...state,
        haveServiceDestination: action.haveServiceDestination,
      };
    default:
      return state;
  }
};
